<script setup>
import { getTimesBetween } from '@/utilities';
import { zipObject } from 'lodash';
import { computed, inject } from 'vue';

const dayjs = inject('dayjs');

const props = defineProps({
    shift: Object,
    form: Object,
});

const startTimeOptions = computed(() => {
    const times = getTimesBetween(
        dayjs(props.shift.start_by_flexer).subtract(12, 'hour'),
        dayjs(props.shift.start_by_flexer).add(12, 'hour'),
        'minute',
        15
    );

    return zipObject(
        times.map((e) => e.format('YYYY-MM-DD HH:mm')),
        times.map((e) => e.format('dd D MMM, HH:mm'))
    );
});

const endTimeOptions = computed(() => {
    const times = getTimesBetween(
        dayjs(props.shift.end_by_flexer).ceil(15, 'minutes').subtract(12, 'hour'),
        dayjs(props.shift.end_by_flexer).ceil(15, 'minutes').add(12, 'hour'),
        'minute',
        15
    );

    times.push(dayjs(props.shift.end_by_flexer));

    times.sort((a, b) => (a.isBefore(b) ? -1 : 1));

    return zipObject(
        times.map((e) => e.format('YYYY-MM-DD HH:mm')),
        times.map((e) => e.format('dd D MMM, HH:mm'))
    );
});

const breakOptions = computed(() => {
    var times = {};

    for (var i = 0; i < 9; i++) {
        times[15 * i * 60] = 15 * i + ' min';
    }
    return times;
});

const isCompleted = props.shift.status === 'completed';

// Filled data by location
const filledStartTime = computed(() => {
    if (props.shift.start_by_location === null) {
        return 'N/A';
    }

    return dayjs(props.shift.start_by_location).format('dd D MMM, HH:mm');
});
const filledEndTime = computed(() => {
    if (props.shift.end_by_location === null) {
        return 'N/A';
    }

    return dayjs(props.shift.end_by_location).format('dd D MMM, HH:mm');
});
const filledBreak = computed(() => {
    if (props.shift.break_by_location === null) {
        return 'N/A';
    }

    return dayjs.duration(props.shift.break_by_location, 's').format('HH:mm');
});
</script>

<template>
    <div v-if="!isCompleted">
        <FormKit :label="$t('Start time')" type="text" name="start" :value="filledStartTime" disabled />
        <FormKit :label="$t('End time')" type="text" name="end" :value="filledEndTime" disabled />
        <FormKit :label="$t('Break')" type="text" name="break" :value="filledBreak" disabled />
    </div>
    <div v-else>
        <FormKit
            :label="$t('Start time')"
            type="select"
            name="start"
            :options="startTimeOptions"
            select-icon="down"
            v-model="form.start"
            :value="form.start"
            :errors="form.errors.start"
        />
        <FormKit
            :label="$t('End time')"
            type="select"
            name="end"
            :options="endTimeOptions"
            select-icon="down"
            v-model="form.end"
            :value="form.end"
            :errors="form.errors.end"
        />
        <FormKit
            :label="$t('Break')"
            type="select"
            name="break"
            :options="breakOptions"
            select-icon="down"
            v-model="form.break"
            :value="form.break"
            :errors="form.errors.break"
        />
    </div>
</template>
